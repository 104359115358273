import { Theme } from '@/app'
import { CenterWrapper, React, View, Text, Image, Touchable, Tools } from '@/lib'
import { useState } from '@codeleap/common'
import { TeamItemProps } from './_data'
import { Carousel } from '@/components'

import { Assets } from '@/assets'

export type TeamItemProps = {
  name: string
  email?: string
  link?: string
  placeholder?: string
  img_a?: string
  img_b?: string
  role: string
}

export const data: TeamItemProps[] = [
  {
    name: `Victor`,
    img_a: Assets.Team.Victor[1],
    img_b: Assets.Team.Victor[2],
    role: `Co-Founder & CTO`,
  },
  {
    name: `Vivian`,
    img_a: Assets.Team.Vivian[1],
    img_b: Assets.Team.Vivian[2],
    role: `Co-Founder & COO`,
  },
  {
    name: `Evair`,
    img_a: Assets.Team.Evair[1],
    img_b: Assets.Team.Evair[2],
    role: `Data Scientist`,
  },
  {
    name: `Leonardo`,
    img_a: Assets.Team.Leo[1],
    img_b: Assets.Team.Leo[2],
    role: `Frontend Engineer `,
  },
  {
    name: `Matt`,
    img_a: Assets.Team.Matheus[1],
    img_b: Assets.Team.Matheus[2],
    role: `Project Manager`,
  },
  {
    name: `Yumi`,
    img_a: Assets.Team.Yumi[1],
    img_b: Assets.Team.Yumi[2],
    role: `Head of Design`,
  },
  {
    name: `Kenzo`,
    img_a: Assets.Team.Kenzo[1],
    img_b: Assets.Team.Kenzo[2],
    role: `Frontend Engineer`,
  },
  {
    name: `Sarah`,
    img_a: Assets.Team.Sarah[1],
    img_b: Assets.Team.Sarah[2],
    role: `UI/UX Designer`,
  },
  {
    name: `Vinny`,
    img_a: Assets.Team.Vini[1],
    img_b: Assets.Team.Vini[2],
    role: `Project Manager`,
  },
  {
    name: `Paulo`,
    img_a: Assets.Team.Paulo[1],
    img_b: Assets.Team.Paulo[2],
    role: `Frontend Engineer`,
  },
  {
    name: `Fernando`,
    img_a: Assets.Team.Fernando[1],
    img_b: Assets.Team.Fernando[2],
    role: `Frontend Engineer`,
  },
  {
    name: `Gabriel`,
    img_a: Assets.Team.Gabriel[1],
    img_b: Assets.Team.Gabriel[2],
    role: `Frontend Engineer`,
  },
  {
    name: `Felipe`,
    img_a: Assets.Team['luiz-felipe'][1],
    img_b: Assets.Team['luiz-felipe'][2],
    role: `Frontend Engineer `,
  },
  {
    name: `Luana`,
    img_a: Assets.Team.Luana[1],
    img_b: Assets.Team.Luana[2],
    role: `UI/UX Designer`,
  },
  {
    name: `Garcez`,
    img_a: Assets.Team.Garcez[1],
    img_b: Assets.Team.Garcez[2],
    role: `Frontend Engineer`,
  },
  {
    name: `Danilo`,
    img_a: Assets.Team.Danilo[1],
    img_b: Assets.Team.Danilo[2],
    role: `Frontend Engineer`,
  },
  {
    name: `Filipe`,
    img_a: Assets.Team.Filipe[1],
    img_b: Assets.Team.Filipe[2],
    role: `Frontend Engineer`,
  },
  {
    name: `Takuya`,
    img_a: Assets.Team.Takuya[1],
    img_b: Assets.Team.Takuya[2],
    role: `Frontend Engineer`,
  },
  {
    name: `Gini`,
    img_a: Assets.Team.Gini[1],
    img_b: Assets.Team.Gini[2],
    role: `Talent Manager`,
  },
  {
    name: `José`,
    img_a: Assets.Team.Jose[1],
    img_b: Assets.Team.Jose[2],
    role: `QA Tester`,
  },
  {
    name: `Join us!`,
    email: `apply@codeleap.co.uk`,
    link: `/careers`,
    role: `See our careers page`,
    placeholder: `?`,
  },
]

export const Team = () => {

  const small = Theme.hooks.down('small')
  const { padding } = Tools.getMaxContentWidth()

  const renderItem = ({ item }: { item: TeamItemProps }) => {

    const [hover, setHover] = useState(false)

    if (item.placeholder) {
      return (
        <>
          <View style={[styles.cardWrapper, styles.roleWrapper]}>
            <Text style={styles.placeholderText} text={item?.placeholder}/>
          </View>
          <Touchable variant={'column'} to={item.link} openNewTab>
            <Text variant={`p1 white bold marginTop:3`} text={item.name}/>
            <Text variant={`p1 white marginTop:1`} text={item.role}/>
          </Touchable>
        </>
      )
    }

    return (
      <View style={styles.cardWrapper} onHover={setHover}>
        <View>
          <Image source={item.img_a} style={styles.image} alt={'team member photo'}/>
          <Text variant={`p1 white bold marginTop:3`} text={item.name}/>
          <Text variant={`p1 white marginTop:1`} text={item.role}/>
        </View>
        <View style={[styles.altImageWrapper, hover && styles.altImageWrapperHover]}>
          <Image source={item.img_b} style={styles.image} alt={'team member photo'}/>
        </View>
      </View>
    )
  }

  return (
    <View style={styles.wrapper}>

      <CenterWrapper contentContainerStyle={[styles.textWrapper, { paddingLeft: small && padding }]} centerContent={!small}>
        <Text variant={`h5 semiBold uppercase primary marginBottom:3`} text={`Our Team`}/>
        <Text
          variant={`h3 white`}
          style={styles.description}
          text={`Say hello! We are innovators. Techies. Computer geeks. Design perfectionists. World Changers.`}
        />
      </CenterWrapper>

      <Carousel data={data} renderItem={renderItem}/>

    </View>
  )
}

const DESKTOP_IMAGE_SIZE = 350
const TABLET_IMAGE_SIZE = 350
const MOBILE_IMAGE_SIZE = 250

const styles = {
  wrapper: {
    paddingTop: Theme.spacing(20),
    paddingBottom: Theme.spacing(20),
    minHeight: 700,
    position: 'relative',
    [Theme.media.down('mid')]: {
      paddingTop: Theme.spacing(10),
      paddingBottom: Theme.spacing(10),
    },
    [Theme.media.down('small')]: {
      minHeight: 600,
    },
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    [Theme.media.down('small')]: {
      maxWidth: '60%',
    },
  },
  description: {
    marginBottom: Theme.spacing(10),
    [Theme.media.down('small')]: {
      marginBottom: Theme.spacing(3),
    },
  },
  image: {
    objectFit: 'cover',
    width: DESKTOP_IMAGE_SIZE,
    height: DESKTOP_IMAGE_SIZE,
    borderRadius: Theme.values.borderRadius.smallish,
    [Theme.media.down('largeish')]: {
      width: TABLET_IMAGE_SIZE,
      height: TABLET_IMAGE_SIZE,
    },
    [Theme.media.down('small')]: {
      width: MOBILE_IMAGE_SIZE,
      height: MOBILE_IMAGE_SIZE,
    },
    [Theme.media.down('tiny')]: {
      width: MOBILE_IMAGE_SIZE * 0.6,
      height: MOBILE_IMAGE_SIZE * 0.6,
    },
  },
  cardWrapper: {
    position: 'relative',
  },
  altImageWrapper: {
    opacity: 0,
    transition: '500ms',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  altImageWrapperHover: {
    opacity: 1,
    transition: '300ms',
  },
  roleWrapper: {
    ...Theme.center,
    ...Theme.flex,
    textAlign: 'center',
    background: Theme.colors.primary,
    width: DESKTOP_IMAGE_SIZE,
    height: DESKTOP_IMAGE_SIZE,
    borderRadius: Theme.values.borderRadius.smallish,
    [Theme.media.down('largeish')]: {
      width: TABLET_IMAGE_SIZE,
      height: TABLET_IMAGE_SIZE,
    },
    [Theme.media.down('small')]: {
      width: MOBILE_IMAGE_SIZE,
      height: MOBILE_IMAGE_SIZE,
    },
    [Theme.media.down('tiny')]: {
      width: MOBILE_IMAGE_SIZE * 0.6,
      height: MOBILE_IMAGE_SIZE * 0.6,
    },
  },
  placeholderText: {
    color: 'white',
    fontSize: 45,
  },
}

export default Team
